@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap');
 
*{
margin: 0;
padding: 0;
box-sizing: border-box;
}
.container {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

  body{
    font-family: 'Inter';
  }

.description {
  line-height: 1.5;
  font-size: 1.5rem;
  text-align: center;
}

.main-container {
  padding: 30px;
}
code {
  background-color: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
}

.App-intro {
  text-align: center;
  font-family: 'Inter';
  padding-top: 40px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
h1{
  font-size: 3.2rem;
}
.link-blue {
  color: #0070f3;
  text-decoration: none;
}
.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: black;
}
@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

.main{
  padding: 5rem 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
}
.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  max-width: 800px;
  margin-top: 3rem;
}

.card {
  margin: 1rem;
  flex-basis: 45%;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h3 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}